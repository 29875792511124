import React, { useEffect, useState } from "react";
import Logo from "../../images/dany.svg";
import "./Updating.css";

export default function App() {
  const data = {
    name: "Daniel Guglielmi",
    message:
      "This portfolio is being updated. In the meantime, feel free to contact me by email.",
    email: "dag@danielguglielmi.dev",
  };
  const [mensaje, setMensaje] = useState("");
  const [counter, setCounter] = useState(0);
  const longitud = data.message.length;

  useEffect(() => {
    const tick = setInterval(() => {
      if (counter <= longitud) {
        setMensaje(data.message.substring(0, counter));
        setCounter(counter + 1);
        console.log(counter)
      }
    }, 50);

    return () => clearInterval(tick);
  }, [counter]);


  return (
    <div className="root">
      <div className="FirstTitle">
        <div className="Imagen">
          <img src={Logo} width="100px" height="120px" alt=""></img>
        </div>
        {data.name}
        <div className="SecondTitle">email: {data.email}</div>
        <div className="Last ">{mensaje}</div>
      </div>
    </div>
  );
}
