import React from 'react';
import Updating from './components/Updating/Updating'

function App() {
  return (
    <div className="App">
      <Updating />
    </div>
  );
}

export default App;
